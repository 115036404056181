<template>
  <f7-page>
    <div class="logo">
      <img src="@/assets/logo_hamelin_solutions.png">
    </div>
    <f7-login-screen
      :opened="loginScreenOpened"
      @loginscreen:closed="loginScreenOpened = false"
    >
      <f7-page login-screen>
        <f7-block class="logo-small">
          <img src="@/assets/icon_hamelin_solutions_small.png">
        </f7-block>
        <f7-login-screen-title>
          <span class="login-title">DDH</span>
          <span class="login-subtitle">Etats des lieux</span>
        </f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            label="Votre login"
            type="text"
            placeholder="Votre login"
            :value="username"
            @input="username = $event.target.value"
          />
          <f7-list-input
            label="Votre mot de passe"
            type="password"
            placeholder="Votre mot de passe"
            :value="password"
            @input="password = $event.target.value"
            @keyup.enter="attemptLogin"
          />
        </f7-list>
        <f7-list>
          <f7-list-button
            v-if="error"
            class="color-red"
          >
            {{ error }}
          </f7-list-button>
          <f7-list-button v-else-if="isLogging">
            <f7-preloader /> Connexion...
          </f7-list-button>
          <f7-list-button
            v-else
            @click="attemptLogin"
          >
            Me connecter
          </f7-list-button>
          <f7-block-footer />
        </f7-list>
      </f7-page>
    </f7-login-screen>
  </f7-page>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import {f7ready} from "framework7-vue";
  import publicFirewall from "@/components/mixins/publicFirewall";

  export default {
    name: "MainLogin",
    mixins: [publicFirewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        loginScreenOpened: false,
        username: null,
        password: null,
        isLogging: false,
        error: null
      };
    },
    computed: {
      ...mapState('security', [
        'jwtToken',
        'refreshToken'
      ])
    },
    mounted: function () {
      f7ready(() => {
        if (!this.isPublicRoute) {
          if (this.jwtToken) {
            this.postLoginRedirect();
          } else if (this.refreshToken) {
            this.refreshLogin()
              .then(this.postLoginRedirect)
              .catch(this.showLogin);
          } else {
            this.showLogin();
          }
        }
      });
    },
    methods: {
      ...mapActions('security', [
        'login',
        'refreshLogin',
        'setJwtToken',
        'setRefreshToken'
      ]),
      showLogin: function () {
        setTimeout(() => this.loginScreenOpened = true, 1000);
      },
      attemptLogin: function () {
        if(!this.username || !this.password) {
          this.showError('Veuillez renseigner vos identifiants');
        } else {
          this.isLogging = true;
          this.login({
            'login': this.username,
            'password': this.password
          }).then((response) => {
            if (response.data.token !== undefined) {
              this.setJwtToken(response.data.token);
              this.setRefreshToken(response.data.refresh);

              this.loginScreenOpened = false;
              this.postLoginRedirect();
            } else {
              this.isLogging = false;
              this.showError('Erreur de connexion (missing token in login response)');
            }
          }).catch((error) => {
            let response = error.response?.data || null;
            this.isLogging = false;
            if (!response) {
              this.showError('Erreur de connexion');
              console.error(error);
            } else if (response.code === 401) {
              this.showError('Les identifiants ne sont pas valides');
            } else if (response.code !== undefined && response.message !== undefined) {
              this.showError('Erreur de connexion '+response.code+' ('+response.message+')');
            } else {
              this.showError('Erreur de connexion');
              console.log('Login error', response);
            }
          });
        }
      },
      showError: function (message) {
        this.error = message;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      },
      postLoginRedirect: function () {
        //When entering url from address bar, framework7 mounts main route (this one)...
        if(this.f7router.currentRoute?.name !== undefined && this.f7router.currentRoute.name !== 'login') {
          this.$nextTick().then(() => {
            this.f7router.navigate(this.f7router.currentRoute, {reloadCurrent: true});
          });
        } else {
          let redirect = this.f7router.previousRoute.name !== undefined && this.f7router.previousRoute.name !== 'login'
            ? this.f7router.previousRoute.name
            : 'edlsLocal';

          let params = null;
          if (redirect !== 'edlsLocal') {
            params = this.f7router.previousRoute.params;
          }

          this.$nextTick().then(() => {
            this.f7router.navigate({name: redirect, params: params}, {reloadCurrent: true});
          });
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      max-width: 40%;
      max-height: 40%;
    }
  }
  .logo-small {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      max-width: 35%;
      max-height: 35%;
    }
  }
  .login-title {
    font-size: 28px;
    display: block;
    color: var(--f7-theme-color-very-dark-grey);
  }
  .login-subtitle {
    font-size: 18px;
    display: block;
    color: var(--f7-theme-color-dark-grey);
  }
</style>
