<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Nombre d'EDLs par utilisateur"
      back="account"
    >
      <template #subnav>
        <f7-searchbar
          search-container=".search-list"
          search-in=".item-title"
          :disable-button="!$theme.aurora"
        />
      </template>
    </navigation-top>

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list
        inline-labels
        no-hairlines-md
        inset
      >
        <f7-list-input
          v-if="groups.length > 0"
          type="select"
          required
          :value="groupId"
          @input="groupId = $event.target.value"
        >
          <option
            v-if="isSuperAdmin"
            value=""
          >
            --- Tous les groupes
          </option>
          <option
            v-else
            value=""
          >
            Choisissez un groupe...
          </option>
          <option
            v-if="isSuperAdmin"
            value="none"
          >
            --- Sans groupe
          </option>
          <optgroup
            v-for="(group, groupId) in sortedGroups"
            :key="groupId"
            label="---"
          >
            <option
              v-if="group.id !== me?.group?.parent?.id"
              :value="group.id">
              {{group.name}}
            </option>
            <option
              v-for="child in group.children"
              :key="child.id"
              :value="child.id"
            >
              > {{child.name}}
            </option>
          </optgroup>
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-input>

        <f7-list-input
          label="Du"
          type="date"
          :value="startDate"
          @input="startDate = $event.target.value"
        />

        <f7-list-input
          label="Au"
          type="date"
          :value="endDate"
          @input="endDate = $event.target.value"
        />

        <f7-list-button
          title="Filtrer"
          @click="fetchData"
        />
      </f7-list>

      <f7-block class="text-align-center" v-if="totalCount > 0">
        <f7-block-title>TOTAL </f7-block-title>
        <f7-badge>{{totalCount}}</f7-badge> EDLs
      </f7-block>

      <f7-list v-if="items && items.length > 0">
        <f7-list-item
          v-for="item in items"
          :key="item.id"
          :title="item.login"
          :footer="getGroup(item)"
          :badge="item.count"
        />
      </f7-list>
      <f7-block v-else-if="items">
        <p class="text-align-center">
          Aucun EDL n'a été réalisé pendant cette période pour ce groupe
        </p>
      </f7-block>
    </f7-page-content>
  </f7-page>
</template>

<script>
import api from '@/api';
import NavigationBottom from "@/components/layout/NavigationBottom";
import NavigationTop from "@/components/layout/NavigationTop";
import firewall from "@/components/mixins/firewall";
import {format, startOfYear} from 'date-fns';
import {mapActions, mapGetters} from "vuex";
import { f7 } from "framework7-vue";

export default {
  name: "UsageStatistics",
  components: {NavigationBottom, NavigationTop},
  mixins: [firewall],
  data() {
    return {
      groupId: null,
      currentGroupId: null,
      startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      items: null
    }
  },
  computed: {
    ...mapGetters('userGroup', [
      'getAllUserGroups',
      'getAllChildrenOfGroupId',
    ]),
    groups() {
      return this.isSuperAdmin ? this.getAllUserGroups : this.getAllChildrenOfGroupId(this.me.groupId);
    },
    sortedGroups() {
      return this.groups.reduce((sorted, group) => {
        if(group.parentId !== undefined && group.parent !== undefined) {
          sorted[group.parentId] = sorted[group.parentId] || structuredClone(group.parent);
          sorted[group.parentId].children = sorted[group.parentId].children || [];
          sorted[group.parentId].children.push(group);
        } else {
          sorted[group.id] = structuredClone(group);
        }
        return sorted;
      }, {});
    },
    totalCount() {
      return this.items ? this.items.reduce((total, item) => total + item.count, 0) : 0;
    }
  },
  mounted() {
    this.limitToAdmins();
    if(!this.isSuperAdmin) {
      this.groupId = this.me.groupId;
      this.$nextTick(() => this.fetchData());
    }
    this.fetchUserGroups();
  },
  methods: {
    ...mapActions('userGroup', [
      'fetchUserGroups',
    ]),
    fetchData() {
      if(!this.isSuperAdmin && (this.groupId === null || this.groupId === '')) {
        f7.dialog.alert('Il est impératif de choisir un groupe pour lequel afficher les statistiques');
      } else {
        f7.preloader.show();
        this.pagePreloaderRunning = true;

        api.edlStats(this.groupId, this.startDate, this.endDate)
          .then((response) => this.items = response.data)
          .then(() => this.currentGroupId = this.groupId)
          .then(() => f7.preloader.hide());
      }
    },
    getGroup(item) {
      if(this.currentGroupId === null || this.currentGroupId === '') {
        return (item.parentGroupName ? item.parentGroupName + ' > ' : '') + (item.groupName || '')
      } else if(+this.currentGroupId === item.parentGroupId) {
        return (item.groupName || '');
      } else {
        return '';
      }
    }
  }
}
</script>

<style scoped>

</style>
