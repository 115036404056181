import {unobserve} from "@/store/helper";
import {getConfig} from "@/data/edlDataStructureModuleEntryRate";

//https://deepdash.io/
import lodash from "lodash";
import deepdash from 'deepdash-es';
const _ = deepdash(lodash);

//Add ids to each element
//Each element will have a unique id to keep track of them after sorting or renaming
export const fillIds = (edl) => {
  return _.eachDeep(unobserve(edl), (value, key, parentValue, context) => {
    if ((context.parent !== undefined//add module
      && typeof value === 'object'
      && value !== null
      && (context.parent.key === undefined //module
        || context.parent.key === 'content' //subEntry
        || context.parent.key === 'entry' //entry
      )) && (value.id === null || value.id === undefined)) { //if id not set yet
      value.id = +key;
    }
  });
}

export const resetCleaning = (edl, newValue = null) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'rate' && value.cleaning !== undefined) {
      value.cleaning = newValue;
      if (parentValue.name === 'Ménage' && value.other !== undefined) {
        value.other = null;
      }
    }
  });
}

export const resetCondition = (edl, newValue = null) => {
  return _.eachDeep(edl, (value, key) => {
    if (key === 'rate' && value.condition !== undefined) {
      value.condition = newValue;
    }
  });
}

export const resetPhotos = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'name' && value === 'Photos' && _.isArray(parentValue.content)) {
      parentValue.content = [];
    }
  });
}

export const resetPainting = (edl) => {
  const paintedEntryNames = [
    "Murs",
    "Mur",
    "Accès",
    "Gauche",
    "Face",
    "Droite",
    "Plafond",
    "Plafonds",
    "Plinthe",
    "Plinthes",
    "Porte",
    "Portes",
    "Intérieur",
    "Intérieurs",
    "Extérieur",
    "Extérieurs",
  ];
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (paintedEntryNames.includes(parentValue.name) && key === 'rate' && value.condition !== undefined && value.condition !== "Neuf") {
      value.condition = "Bon état";
      if (value.other !== undefined && value.other !== null) {
        value.other = "";
      }
    }
  });
}

export const resetRenovation = (edl) => {
  return _.eachDeep(edl, (value, key) => {
    if (key === 'rate' && value.condition !== undefined && value.condition !== "Neuf") {
      value.condition = "Bon état";
      if (value.other !== undefined && value.other !== null) {
        value.other = "";
      }
      if (value.state !== undefined && value.state !== "" && value.state !== null) {
        value.state = "Fonctionne";
      }
    }
  });
}

export const removeCosting = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'costingId') {
      delete parentValue.costingId;
    }
  });
}

export const resetOpened = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'opened') {
      delete parentValue.opened;
    }
  });
}

export const resetToQuote = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'toQuote') {
      delete parentValue.toQuote;
    }
  });
}

export const resetPreQuote = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'preQuote') {
      delete parentValue.preQuote;
    }
  });
}

export const presetCondition = (edl, conditionValue = null) => {
  if (conditionValue) {
    return resetCondition(edl, conditionValue);
  }
  return edl;
}

export const presetCleaning = (edl, cleaningDefault = null) => {
  return _.eachDeep(edl, (value, key) => {
    if (key === 'rate' && value.cleaning !== undefined && value.cleaning === null && cleaningDefault) {
      value.cleaning = cleaningDefault;
    }
  });
}

//In some legacy edls, empty rates were left as [] -> fixed by replacing them with objects and null rates
export const fixLegacyEmptyRates = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue, context) => {
    if (key === 'rate' && _.isArray(value) && value.length === 0) {
      parentValue.rate = {};
      _.each(context.parents, (parent) => {
        if (parent.value.rateInfo !== undefined) {
          let rates = parent.value.rateInfo;
          _.each(rates, (rate, rateName) => {
            parentValue.rate[rateName] = null;
          });
        }
      });
    }
  });
}

//In some legacy edls, some rates contained an "id" key by mistake => fixed by removing them
export const fixLegacyIdRates = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'rate') {
      parentValue.rate = _.pickBy(value, (rateValue, rateName) => {
        return rateName !== 'id';
      });
    } else if (key === 'rateInfo') {
      parentValue.rateInfo = _.pickBy(value, (rateValue, rateName) => {
        return rateName !== 'id';
      });
    }
  });
}

export const fixLegacyRateValues = (edl) => {
  return _.eachDeep(edl, (value, key) => {
    //Fix historical error in naming (extra space at the end)
    if (key === 'rateInfo' && value.state !== undefined && value.state !== null && value.state.includes("Fonctionne ")) {
      let index = value.state.findIndex((state) => state === "Fonctionne ");
      value.state[index] = "Fonctionne";
    }
    if (key === 'rate' && value.state !== undefined && value.state === "Fonctionne ") {
      value.state = "Fonctionne";
    }
  });
}

export const fixLegacyTypeRates = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    //Fix historical error where multiple "type" rates existed (ex. both "type" and "typeWalls" - the latter shouldn't have existed)
    //Only exception: typeCustom$ (custom types)
    //Keep rates with values
    if (key === 'rate') {
      let migrateType = null;
      parentValue.rate = _.pickBy(value, (rateValue, rateName) => {
        if (rateName !== 'type' && rateName !== 'typeReleve' && !rateName.startsWith('typeCustom') && rateName.includes('type')) {
          if (rateValue !== null && (value.type === undefined || value.type === null)) { //assign value to "type" if "type" doesn't already have a value
            migrateType = rateValue;
          }
          return false;
        }
        return true;
      });
      //This strategy was needed to handle cases where "type" key didn't even exist. Assigning during pickBy did not work.
      if (migrateType !== null) {
        parentValue.rate = Object.assign(parentValue.rate, {type: migrateType});
      }
    }
  });
}

export const fixLegacyEquipmentRates = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    //Fix historical error where multiple "equipment" rates existed (ex. both "equipment" and "equipementEnergie1" - the latter shouldn't have existed)
    //Keep rates with values
    if (key === 'rate') {
      let migrateEquipment = null;
      parentValue.rate = _.pickBy(value, (rateValue, rateName) => {
        if (rateName !== 'equipment' && rateName.includes('equipement')) {
          if (rateValue !== null && (value.equipment === undefined || value.equipment === null)) { //assign value to "type" if "type" doesn't already have a value
            migrateEquipment = rateValue;
          }
          return false;
        }
        return true;
      });
      //This strategy was needed to handle cases where "type" key didn't even exist. Assigning during pickBy did not work.
      if (migrateEquipment !== null) {
        parentValue.rate = Object.assign(parentValue.rate, {equipment: migrateEquipment});
      }
    }
  });
}

export const addDefaultRatesIfMissing = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue, context) => {
    if (_.has(value, 'rate') && _.has(value, 'rateInfo')) {
      _.each(value.rateInfo, (rateValues, rateName) => {
        let config = getConfig(rateName);
        if (config !== undefined) {
          let rateVariable = config.rateVariable || rateName;
          if (!_.has(value.rate, rateVariable)) {
            value.rate[rateVariable] = null;
          }
        }
      });
    } else if (_.has(value, 'rate') && _.has(context, '_item.parent.parent.value.rateInfo')) {
      _.each(context._item.parent.parent.value.rateInfo, (rateValues, rateName) => {
        let config = getConfig(rateName);
        if (config !== undefined) {
          let rateVariable = config.rateVariable || rateName;
          if (!_.has(value.rate, rateVariable)) {
            value.rate[rateVariable] = null;
          }
        }
      });
    }
  });
}

export const presetTotalKeysCount = (edl) => {
  return _.eachDeep(edl, (value, key, parentValue) => {
    //Before total keys were automatic and readonly, some were wrong or simply not set. Fix this when duplicating an old EDL
    if (_.has(value, 'name') && value.name === 'Total des clés') {
      let total = 0;
      _.forEach(parentValue, (key) => {
        if (_.has(key, 'rate') && _.has(key.rate, 'number') && key.name !== 'Total des clés' && key.rate.number !== null && key.rate.number !== '') {
          total += parseInt(key.rate.number);
        }
      });
      value.rate.number = total.toString();
    }
  });
}

export const clearRenters = (info) => {
  _.each(info, (value, key) => {
    if (key === 'renter') {
      info[key]['email'] = null;
      info[key]['name'] = null;
      info[key]['tel'] = null;
      info[key] = _.omit(info[key], 'sortantAddr');
      info[key] = _.omit(info[key], 'sortantCodePostal');
      info[key] = _.omit(info[key], 'sortantVille');
    } else if (key.includes('renter')) {
      info = _.omit(info, key);
    }
  });

  info.nbRenters = 1;

  return info;
}

export const clearExtraEmails = (info) => {
  _.each(info, (value, key) => {
    if (key.includes('extraEmail')) {
      info = _.omit(info, key);
    }
  });

  info.nbExtraEmails = 0;

  return info;
}

export const clearGarant = (info) => {
  _.each(info, (value, key) => {
    if (key.includes('renter') && _.has(info[key], 'represented')) {
      delete info[key].represented;
    }
  });

  return _.omit(info, 'garant');
}

export const setDefaultLocaleIfMissing = (info) => {
  info.locale = 'fr';
  return info;
}
